<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <SupplierForm />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import SupplierForm from "../components/supplier/supplierform/SupplierForm.vue";

export default {
  name: "CreateSupplier",

  components: {
    Navbar,
    LeftMenu,
    SupplierForm,
  },

  data: function() {
    return {};
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {},
};
</script>
